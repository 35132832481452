body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Colors */
:root {
  --primary-color: #734bd1;
  --secondary-color: #595959;
  --white-color: #FFFFFF;
  --white-color-100: #EBE7F5;
   --mustard-color: #ffc400;
  --red-color: #ff5001;
  --orange-color: #F6780D;
  --grey-color:#FCFCFC;
  --light-grey: #F8F7FC;
  --light-grey-100: #FBFAFD;
  --light-grey-200:#EDEEED;
  --light-grey-300: #F1F1F1;
  --dark-grey: #707070;
  --black-color: #000000;
}
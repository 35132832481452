/* use to add border top for Upcoming Meeting (purple)*/
.card-border-meeting {
  border-top: 8px solid var(--primary-color) !important;
}
.notification{
  padding:1rem;
}
.notif-title{
  color:var(--secondary-color);
  font-weight: 700;
  padding-left: 1rem;
  padding-top: 1rem;
  font-size: 1.8rem;
}
.scheduled-title{
  color:var(--secondary-color);
  font-weight: 700;
  padding-top: 1rem;
  font-size: 1.8rem;
}
/* .footer-duration {
  padding: 0.9rem !important;
  border-radius: 8px;
  font-size: large;
  text-align: center;
} */
#scrollbox .row>* {
  display: flex;
}
#scrollbox .row {
  align-items: stretch;
}

.col-lg-4 > .card {
  min-width: 100%;
}

.vertical-ellipsis {
  position: absolute;
  right: 8px;
  top: 20px;
  cursor: pointer;
}

.popup {
  width: 8rem;
  height: 5rem;
  position: absolute;
  right: 2rem;
  top: 0.5rem;
  padding: 10px 12px;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  font-size: medium;
  background-color: var(--white-color);
  color: var(--secondary-color);
  border-radius: 10px;
  box-shadow: 1px 1px 20px 2px #00000029;
}

.popup-item:hover {
  cursor: pointer;
  background-color: var(--light-grey);
  color: var(--primary-color);
}
/* Approval Pending */

/* use to add border top for Approval Pending*/
.card-border-upcoimg-meeting {
  border-top: 8px solid var(--mustard-color) !important;
}

.vertical-ellipsis {
  position: absolute;
  right: 8px;
  top: 6px;
  cursor: pointer;
}

.popup {
  width: 8rem;
  height: 3rem;
  position: absolute;
  right: 2rem;
  top: 0.5rem;
  padding: 10px 12px;
  align-items: center;
  vertical-align: middle;
  text-align: left;
  font-size: medium;
  background-color: var(--white-color);
  color: var(--secondary-color);
  border-radius: 10px;
  box-shadow: 1px 1px 20px 2px #00000029;
}

.popup-item:hover {
  cursor: pointer;
  background-color: var(--light-grey);
  color: var(--primary-color);
}